var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("img", {
    staticClass: "qr-code-activity-popup",
    attrs: { src: _vm.qrcode, alt: "QR Code" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
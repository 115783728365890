




import { defineComponent } from "@vue/composition-api"
import { useQRCode } from "@vueuse/integrations/useQRCode"

export default defineComponent({
  name: "QrCodeActivityPopup",
  setup() {
    const text = window.location.href + "?auth=0&anon=1"
    const qrcode = useQRCode(text, { width: 1500 })
    return { qrcode }
  }
})
